<template>
  <iframe :src="url" frameborder="0"></iframe>
</template>

<script>
export default {
  data() {
    return {
      serverUrl: "/web/viewer.html",
      pdfUrl: "",
      url: "",
    };
  },
  mounted() {
    this.pdfUrl = localStorage.getItem("pdfUrl");
    this.url = `${this.serverUrl}?file=${encodeURIComponent(this.pdfUrl)}`;
  },
};
</script>

<style scoped>
iframe {
  width: 100vw;
  height: 100vh;
  vertical-align: top;
}
</style>
